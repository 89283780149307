import React, {useEffect, useState} from "react";
import {useRootContext} from "../../contexts/RootContext";
import {Plus, Trash} from "@phosphor-icons/react";
import LinkFormModal from "./LinkFormModal";

const LinksList = ({projectId}) => {
    const {API} = useRootContext()
    const [links, setLinks] = useState([]);
    const [reload, setReload] = useState(true);
    const [linksLoading, setLinksLoading] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [bulkAction, setBulkAction] = useState(0);


    useEffect(() => {
        if (!reload) return;

        setLinksLoading(true);

        API.getData("/link/list", (links) => {
            setLinks(links);
        });

        setLinksLoading(false);
        setReload(false);
    }, [reload]);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalLinkId, setModalLinkId] = React.useState(0);

    function closeModal() {
        setIsOpen(false);
    }

    function handleDelete(id, noreload) {
        API.getData("/link/delete/" + id, () => {
            if (!noreload) {
                setReload(true);
            }
        });
    }

    if (linksLoading) {
        return ("Načítání...");
    }

    const handleBulkAction = () => {
        switch (bulkAction) {
            case "delete":
                selectedIds.forEach(id => {
                    handleDelete(id, true)
                });
                setReload(true);
                break;
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between w-100 flex-wrap">
                <div className="mb-3 mb-lg-0"><h1 className="h4">Úkoly</h1></div>
            </div>


            <div className="my-3">
                <button onClick={() => {
                    setIsOpen(true);
                    setModalLinkId(null);
                }}
                        className="btn btn-secondary d-inline-flex align-items-center me-2">
                    <Plus size={16} className="me-2"/>
                    Nový odkaz
                </button>
            </div>

            {links?.length > 0 ? (
                <>
                    <div className="d-flex mb-3"><select className="form-select fmxw-200"
                                                         disabled={selectedIds.length === 0}
                                                         defaultValue=""
                                                         onChange={(e) => setBulkAction(e.target.value)}
                    >
                        <option value="">Hromadná akce</option>
                        <option value="delete">Smazat</option>
                    </select>
                        <button disabled={selectedIds.length === 0}
                                onClick={handleBulkAction}
                                className="btn btn-sm px-3 btn-secondary ms-3">Provést
                        </button>
                    </div>

                    <div className="card border-0 shadow mb-4">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover align-items-center">
                                    <thead>
                                    <tr>
                                        <td style={{maxWidth: "15px", width: "15px", minWidth: "15px"}}
                                            className="p-0 ps-2 border-bottom">
                                            <div className="form-check dashboard-check">
                                                <input className="form-check-input" type="checkbox" id="item_all"
                                                       onClick={(e) => {
                                                           if (e.target.checked) {
                                                               setSelectedIds((prev) => [...links.map((link) => link.id)]);
                                                           } else {
                                                               setSelectedIds((prev) => []);
                                                           }
                                                       }}
                                                />
                                                <label className="form-check-label" htmlFor="item_all"/>
                                            </div>
                                        </td>
                                        <th className="border-bottom">Název</th>
                                        <th className="border-bottom">Odkaz</th>
                                        <th className="border-bottom">Kliknutí</th>
                                        <th className="border-bottom">Akce</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {links.map((link, link_key) => (
                                        <tr key={link_key}>

                                            <td className="p-0 ps-2">
                                                <div className="form-check dashboard-check">
                                                    <input className="form-check-input" type="checkbox"
                                                           id={"item_" + link.id}
                                                           checked={selectedIds.includes(link.id)}
                                                           onChange={(e) => {
                                                               if (e.target.checked) {
                                                                   setSelectedIds((prev) => [...prev, link.id]);
                                                               } else {
                                                                   setSelectedIds((prev) => prev.filter((id) => id != link.id));
                                                               }
                                                           }}
                                                    />
                                                    <label className="form-check-label" htmlFor={"item_" + link.id}/>
                                                </div>
                                            </td>
                                            <td className="fw-bold  text-wrap">

                                                <div className="d-flex align-items-center">

                                                    <div>
                                                        <a onClick={() => {
                                                            setModalLinkId(link.id);
                                                            setIsOpen(true)
                                                        }}
                                                           className="text-primary fw-bold">
                                                            {link.name}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td><strong>https://www.vilemis.cz/?referral={link.hash}</strong></td>
                                            <td><span className="fw-normal">{link.clickCounts}</span></td>

                                            <td>
                                                <button
                                                    onClick={() => window.confirm("Opravdu smazat?") && handleDelete(link.id)}
                                                    className="btn btn-sm btn-link" type="button">
                                                    <Trash/>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <p>Zatím odkaz</p>
            )}

            {modalIsOpen && (
                <LinkFormModal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    setIsOpen={setIsOpen}
                    projectId={projectId}
                    id={modalLinkId}
                    callback={() => setReload(true)}/>
            )}
        </>
    );
};

export default LinksList;