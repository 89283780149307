import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import LinksList from "../components/Links/LinksList";

const Client = ({}) => {
    useEffect(() => {
        document.title = "Odkazy";
    }, []);

    return (
        <Routes>
            <Route path="*" element={<LinksList/>}/>
        </Routes>
    );
}

export default Client;