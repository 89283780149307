const Home = ({ }) => {
    return (
        <>
            <div className="row">
                <h1>Nástěnka</h1>

                <p className="alert alert-danger">Připravujeme</p>
            </div>

        </>
    );
};

export default Home;