import React, {useEffect, useState} from "react";
import {useRootContext} from "../../contexts/RootContext";

const linkBlank = {
    name: "",
    userId: 0
}

const LinkForm = ({id, handleSave, projectId}) => {
    const {API} = useRootContext()
    const [link, setLink] = useState(null);

    useEffect(() => {
        if (id) {
            API.getData("/link/single/" + id, (link) => {
                setLink(link);
            });
        } else {
            setLink(linkBlank)
        }
    }, [id, projectId]);

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData(event.target);

        if (id) {
            formData.append("id", id);
        }

        API.postData("/link/save", formData, (data) => {
            setLink(data.link);

            if (handleSave) {
                handleSave(data.link);
            }
        });
    }

    if (!link) {
        return (<>Načítaní..</>)
    }

    return (
        <>
            {link && "name" in link && (
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="hash" value={link.hash}/>
                    <input type="hidden" name="user_id" value={link.userId}/>
                            <div className="mb-3">
                                <label htmlFor="form_edit_name">Název</label>
                                <input defaultValue={link.name} type="text" name="name" className="form-control form-control-lg"
                                       id="form_edit_name"/>
                            </div>
                    <button type="submit" className="btn btn-primary">
                        {id ? "Uložit" : "Přidat"}
                    </button>
                </form>
            )}
        </>
    );
};

export default LinkForm;