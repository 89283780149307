import React from "react";
import LinkForm from "./LinkForm";
import Modal from "../Modal/Modal";

const LinkFormModal = ({ onRequestClose, onAfterOpen, isOpen, setIsOpen, callback, id, projectId }) => {

    function onNewLink(link) {
        setIsOpen(false);

        if (callback) {
            callback(link);
        }
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onAfterOpen={onAfterOpen}
                onRequestClose={onRequestClose}
                title={id ? (
                    <div className="d-flex">
                        Odkaz #{id}
                    </div>
                ):"Nový odkaz"}
                size="md"
            >
                <LinkForm projectId={projectId} handleSave={onNewLink} id={id}/>
            </Modal>
        </>
    );
};

export default LinkFormModal;